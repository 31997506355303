
export const environment = {
      production: true,
      api_url: 'https://api.integrate.io/',
      dashboardUrl: 'undefined',
      authToken: 'undefined',
      HUBSPOT_URL: 'https://gateway.xplenty.com/send-hubspot-leads',
      SEGMENT_KEY: 'H3ZOkXM8CSFmr7edOp7NLxG0zCLSDqos',
      HUBSPOT_CONFIRMATION_WEBHOOK_URL: 'https://gateway.xplenty.com/confirm-elt-lead',
      SOURCE_VERSION: 'c8cb4a7dfe95b370f2fe49652be5e9ab20533c1c',
      SENTRY_ENV: 'production',
      SENTRY_DSN: 'https://c1488dcd61abc554f8f2353651afbef6@o4507576419680256.ingest.us.sentry.io/4507808147898368',
};
